import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  currentUser: User | null;
  currentTenantId = '';
  public assetTypes$: BehaviorSubject<any[]> = new BehaviorSubject(undefined);
  public assetCount$: BehaviorSubject<any> = new BehaviorSubject(0);
  public assets$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentAccountValue.subscribe(user => {
      this.currentUser = user;
      this.currentTenantId = user?.tenantId;
    });
  }

  fetchAssets() {
    this.httpClient
      .get(
        `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/assets`
      )
      .subscribe((assets: any[]) => {
        if (assets) {
          this.assets$.next(assets);
          this.assetCount$.next(assets.length);
        }
      });
  }

  fetchAssetTypes() {
    this.httpClient
      .get(
        `${environment.apiPrefix}${this.currentTenantId}${environment.apiSuffix}/assets/types`
      )
      .subscribe((assetTypes: any[]) => {
        if (assetTypes) {
          this.assetTypes$.next(assetTypes);
        }
      });
  }
}
