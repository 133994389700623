import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastr: ToastrService) {
    this.toastr = toastr;
  }

  show(title, message, theme: 'danger' | 'success' | 'info' | 'warning') {
    this.toastr.show(
      `
      <div class="alert-text"> 
        <span class="alert-title" data-notify="title">${title}</span> 
        <span data-notify="message">${message}</span>
      </div>
      `,
      '',
      {
        timeOut: 2500,
        enableHtml: true,
        tapToDismiss: true,
        closeButton: true,
        titleClass: 'alert-title',
        positionClass: 'toast-top-right',
        toastClass: `ngx-toastr alert alert-dismissible alert-${theme} alert-notify`,
      }
    );
  }
}
